<template>
  <div class="preview">
    <div class="header">
      <div class="title">
        Preview
      </div>
      <div class="action col-gap-4">
        <feather-icon
          icon="ChevronLeftIcon"
          size="24"
          class="cursor-pointer"
          @click="handleChangePreview('prev', true)"
        />
        <feather-icon
          :icon="isPlaying ? 'PauseIcon' : 'PlayIcon'"
          size="20"
          class="cursor-pointer"
          @click="handleTogglePlayAndPause"
        />
        <feather-icon
          icon="ChevronRightIcon"
          size="24"
          class="cursor-pointer"
          @click="handleChangePreview('next', true)"
        />
      </div>
    </div>
    <div class="preview-wrapper">
      <div class="desktop">
        <div class="box">
          <div class="sponsored">
            Sponsored
          </div>
          <div class="final-url">
            <b-img :src="worldIcon" />
            <div class="url-wrapper">
              <div class="brand-name">
                {{ hostname }}
              </div>
              <div class="url">
                {{ hostname }}{{ displayPath.path1.value ? `/${displayPath.path1.value}` : '' }}{{ displayPath.path1.value && displayPath.path2.value ? `/${displayPath.path2.value}` : '' }}
              </div>
            </div>
          </div>
          <div class="headlines">
            {{ previewPreset.headlines.length ? previewPreset.headlines[currentPreview.headlines].join(' - ') : 'Headline 1 - Headline 2' }}
          </div>
          <div class="descriptions">
            {{ previewPreset.descriptions.length ? previewPreset.descriptions[currentPreview.descriptions].join('. ') : 'Description 1. Description 2' }}.
            {{ previewPreset.structuredSnippets }}
            {{ previewPreset.callouts.length ? `${previewPreset.callouts[currentPreview.callouts].join('. ')}.` : '' }}
          </div>
          <div :class="`site-links-preview-${siteLinkPreview}`">
            <div
              v-for="(siteLink, siteLinkIndex) in previewPreset.siteLinks"
              :key="siteLinkIndex"
              class="site-link-item"
            >
              <div class="site-link">
                {{ siteLink.siteLink }}
              </div>
              <div
                v-if="siteLinkPreview === 1 && previewPreset.siteLinks.length !== siteLinkIndex + 1"
                class="separator"
              >
                ·
              </div>
              <template v-if="siteLinkPreview !== 1">
                <div class="description">
                  {{ siteLink.description1 }}
                </div>
                <div class="description">
                  {{ siteLink.description2 }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="outer-frame">
          <div class="box">
            <div class="final-url">
              <b-img :src="worldIcon" />
              <div class="url-wrapper">
                <div class="brand-name">
                  {{ hostname }}
                </div>
                <div class="url">
                  {{ hostname }}{{ displayPath.path1.value ? `/${displayPath.path1.value}` : '' }}{{ displayPath.path1.value && displayPath.path2.value ? `/${displayPath.path2.value}` : '' }}
                </div>
              </div>
            </div>
            <div class="headlines">
              {{ previewPreset.headlines.length ? previewPreset.headlines[currentPreview.headlines].join(' - ') : 'Headline 1 - Headline 2' }}
            </div>
            <div class="descriptions">
              {{ previewPreset.descriptions.length ? previewPreset.descriptions[currentPreview.descriptions].join('. ') : 'Description 1. Description 2' }}.
              {{ previewPreset.structuredSnippets }}
              {{ previewPreset.callouts.length ? `${previewPreset.callouts[currentPreview.callouts].join('. ')}.` : '' }}
            </div>
            <div>
              <div class="site-links-preview-1">
                <div
                  v-for="({ siteLink }, siteLinkIndex) in previewPreset.siteLinks"
                  :key="siteLinkIndex"
                  class="site-link-item"
                >
                  <div class="site-link">
                    {{ siteLink }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleAdsMockupPreview',
  props: {
    finalUrl: {
      type: Object,
      default: () => ({ value: '' }),
    },
    displayPath: {
      type: Object,
      default: () => ({
        path1: { value: '' },
        path2: { value: '' },
      }),
    },
    previewPreset: {
      type: Object,
      default: () => ({
        headlines: [],
        descriptions: [],
        callouts: [],
        structuredSnippets: '',
        siteLinks: [],
      }),
    },
    siteLinkPreview: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isPlaying: true,
      countdownSeconds: 10,
      currentPreview: {
        headlines: 0,
        descriptions: 0,
        callouts: 0,
      },

      // eslint-disable-next-line global-require
      worldIcon: require('@/assets/images/icons/world-icon.svg'),
    }
  },
  computed: {
    hostname() {
      if (this.finalUrl.value.match(/^https?:\/\/(?:www\.)?([^/]+)/i)) {
        return this.finalUrl.value.match(/^https?:\/\/(?:www\.)?([^/]+)/i)[1]
      }

      if (this.finalUrl.value) {
        return this.finalUrl.value
      }

      return 'example.com'
    },
  },
  watch: {
    previewPreset: {
      handler() {
        this.currentPreview = {
          headlines: 0,
          descriptions: 0,
          callouts: 0,
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.startCountdown()
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    handleChangePreview(state, isManuallyChange = false) {
      if (isManuallyChange) {
        clearTimeout(this.timer)
        this.countdownSeconds = 10
        this.startCountdown()
      }

      const previewKeys = Object.keys(this.currentPreview)

      previewKeys.forEach(previewKey => {
        if (this.previewPreset[previewKey].length) {
          if (state === 'next') {
            if (this.currentPreview[previewKey] < this.previewPreset[previewKey].length - 1) {
              this.currentPreview[previewKey] += 1
            } else {
              this.currentPreview[previewKey] = 0
            }
          }

          if (state === 'prev') {
            if (this.currentPreview[previewKey] > 0) {
              this.currentPreview[previewKey] -= 1
            } else {
              this.currentPreview[previewKey] = this.previewPreset[previewKey].length - 1
            }
          }
        }
      })
    },

    handleTogglePlayAndPause() {
      clearTimeout(this.timer)
      this.isPlaying = !this.isPlaying

      if (this.isPlaying) {
        this.startCountdown()
      }
    },

    startCountdown() {
      if (this.countdownSeconds >= 1) {
        this.timer = setTimeout(() => {
          this.countdownSeconds -= 1
          this.startCountdown()
        }, 1000)
      } else {
        this.handleChangePreview('next')
        clearTimeout(this.timer)
        this.countdownSeconds = 10
        this.startCountdown()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  position: sticky;
  top: 16px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .title {
      font-weight: 700;
    }
    .action {
      display: flex;
      align-items: center;
    }
  }
  .preview-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @media (min-width: 992px) {
      max-height: 100%;
    }
    .desktop {
      overflow-x: auto;
      .box {
        width: 600px;
      }
    }
    .sponsored {
      font-size: 14px;
      font-weight: 700;
      color: #202124;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .final-url {
      display: flex;
      align-items: center;
      column-gap: 12px;
      .url-wrapper {
        .brand-name {
          font-size: 14px;
          color: #202124;
          line-height: 20px;
        }
        .url {
          font-size: 12px;
          color: #4D5156;
        }
      }
    }
    .headlines {
      font-size: 20px;
      color: #1A0DAB;
    }
    .descriptions {
      font-size: 14px;
      color: #4D5156;
      line-height: 22.1px;
    }
    .site-links-preview {
      &-1 {
        display: flex;
        .site-link-item {
          display: flex;
          align-items: center;
          .site-link {
            font-size: 14px;
            color: #1A0DAB;
            max-width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .separator {
            font-size: 14px;
            color: #5E5E5E;
            padding: 0 5px;
          }
        }
      }
      &-2, &-3 {
        .site-link-item {
          .site-link {
            font-size: 20px;
            color: #1A0DAB;
          }
          .description {
            font-size: 14px;
            color: #4D5156;
          }
        }
      }
      &-2 {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0 0 16px;
        row-gap: 5px;
        .site-link-item {
          width: 50%;
          flex: 0 0 auto;
          &:nth-child(even) {
            padding-left: 5px;
          }
        }
      }
      &-3 {
        display: flex;
        flex-direction: column;
        padding: 16px 0 0 16px;
        row-gap: 5px;
      }
    }
    .mobile {
      border: 2px solid rgba(0, 0, 0, .5);
      border-bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 30px 15px;
      max-width: 434px;
      margin-top: 30px;
      .outer-frame {
        background-color: #F1F3F4;
        padding: 10px;
        .box {
          word-break: break-word;
          max-width: 380px;
          background-color: white;
          border-radius: 4px;
          padding: 12px 16px 0 16px;
          .headlines {
            color: #1558D6;
            padding-top: 12px;
            padding-bottom: 12px;
          }
          .descriptions {
            line-height: 20px;
          }
          .site-links-preview-1 {
            column-gap: 5px;
            padding-top: 16px;
            padding-bottom: 16px;
            overflow: auto;
            .site-link-item {
              border: 1px solid rgba(0, 0, 0, .1216);
              border-radius: 4px;
              padding: 5px 8px;
              .site-link {
                color: #1558D6;
                max-width: fit-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
</style>
